import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import http from '../../utils/http';


type Token = string;
export type UserInfo = {
  [index: string]: unknown
}
export type UsersState = {
  token: Token,
  userInfo: UserInfo
}

type Login = {
  email: string,
  pass: string,
}
type ThirdToken = {
  email?: string;
  exp?: number;
  header?: string;
  level?: number;
  mobile?: string;
  token: string;
  userName?: string;
}
type LoginThirdToken = {
  userName: string;
}
//创建异步任务
export const thirdGetIMToken = createAsyncThunk('users/thirdGetIMToken', async (payload: ThirdToken) => {
  const ret = await http.post('/api/v1/thirdGetIMToken', payload)
  return ret
})
export const queryUserToken = createAsyncThunk('users/queryToken', async (payload: LoginThirdToken) => {
  const ret = await http.post('/api/v1/getRandomUserToken', payload)
  return ret
})
export const loginAction = createAsyncThunk('users/loginAction', async (payload: Login) => {
  const ret = await http.post('/users/login', payload)
  return ret
})
export const userinfoAction = createAsyncThunk('user/infosAction', async()=> {
  const ret = await http.get('/users/infos')
  return ret
})

const userSlice = createSlice({
  name: 'users',
  initialState: {
    token: '',
    userInfo: {} as UserInfo,
  },
  reducers:  { 
    updateToken(state, action: PayloadAction<Token>) {
      state.token = action.payload
    },
    updateUserInfo(state, action: PayloadAction<UserInfo>) {
      state.userInfo = action.payload
    },
    clearToken(state) {
      state.token = '';
    },
  }
})

export const { updateToken, updateUserInfo, clearToken } = userSlice.actions

export default userSlice.reducer