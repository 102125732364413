import React, { lazy } from 'react';
import { createBrowserRouter, Navigate, RouteObject } from 'react-router-dom';
import {
  CopyOutlined,
  CalendarOutlined,
  WarningOutlined,
  FileAddOutlined,
  ScheduleOutlined,
} from '@ant-design/icons';

const Home = lazy(()=> import('../views/Home/Home'))
const Sign = lazy(()=> import('../views/Sign/Sign'))
const Login = lazy(()=> import('../views/Login/Login'))
const BeforeEach = lazy(()=> import('../components/Beforeach/Beforeach'));
declare module 'react-router' {
  interface IndexRouteObject {
    meta?: {
      menu?: boolean,
      title?: string,
      icon?: React.ReactNode,
      isAuth?: boolean,
    },
    name?:string
  }
  interface NonIndexRouteObject {
    meta?: {
      menu?: boolean,
      title?: string,
      icon?: React.ReactNode,
      isAuth?: boolean,
    },
    name?:string
  }
}
export const routes:RouteObject[] = [
  {
    path: '/',
    element: React.createElement(Navigate, { to: '/sign'}),
  },
  {
    path: '/',
    name: 'home',
    element: React.createElement(BeforeEach, null, React.createElement(Home)),
    meta: {
      menu: true,
      title: '',
      icon: React.createElement(CopyOutlined),
      isAuth: true,
    },
    children: [
      {
        path: 'sign',
        element: React.createElement(Sign),
        name: 'sign',
        meta: {
          menu: true,
          title: 'home',
          icon: React.createElement(CalendarOutlined),
          isAuth: true,
        }
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    element: React.createElement(BeforeEach, null, React.createElement(Login)),
  }
]
const router = createBrowserRouter(routes)

export default router