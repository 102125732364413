import { message } from 'antd';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import store from '../store';
import { clearToken } from '../store/modules/users';

const instance = axios.create({
  baseURL: 'https://webapi.gamechat.ccmeaning.com',
  timeout: 5000,
})

instance.interceptors.request.use(function (config){
  if(config.headers) {
    config.headers.authorization = store.getState().users.token
  }
  return config
}, function(error){
  return Promise.reject(error);
})

instance.interceptors.response.use(function (response){
  if(response.data.errcode === '-1'){
    message.error(response.data.errmsg)
    store.dispatch(clearToken())
    setTimeout(()=>{
      window.location.replace('/login')
    }, 1000)
  }
  return response
}, function(error){
  return Promise.reject(error)
})

interface Data {
  [index: string]: unknown
}

interface Http {
  get: (url: string, data?: Data, config?: AxiosRequestConfig) => Promise<AxiosResponse>,
  post: (url: string, data?: Data, config?: AxiosRequestConfig) => Promise<AxiosResponse>,
  put: (url: string, data?: Data, config?: AxiosRequestConfig) => Promise<AxiosResponse>,
  patch: (url: string, data?: Data, config?: AxiosRequestConfig) => Promise<AxiosResponse>,
  delete: (url: string, data?: Data, config?: AxiosRequestConfig) => Promise<AxiosResponse>,
}

const http: Http = {
  get(url, data, config) {
    return instance.get(instance.getUri()+url, {params: data, ...config})
  },
  post(url, data, config){
    return instance.post(instance.getUri()+url, data, config)
  },
  put(url, data, config){
    return instance.put(instance.getUri()+url, data, config)
  },
  patch(url, data, config){
    return instance.patch(instance.getUri()+url, data, config)
  },
  delete(url, data, config){
    return instance.delete(instance.getUri()+url, {
      data,
      ...config
    })
  }
}
export default http;